<template>
  <div class="bind-card">
    <a-row type="flex" :class="{'mobile-layout':isModal}" justify="space-around" :gutter="[60, 20]">
      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
        <div class="bind-card-img flex">
          <img src="https://img.triproaming.cn/weapp/cards_sample_pictures.png" alt="" srcset="">
        </div>
      </a-col>
      <a-col :xs="xs" :sm="sm" :md="md" :lg="lg" :xl="xl">
        <div class="bind-card">
          <div class="bind-card-title">{{ $t('mycards.bind_title') }}</div>
          <div class="bind-card-desc">{{ $t('mycards.bind_desc') }}</div>
          <div class="bind-card-input">
            <a-input v-model="iccid" type="number" :placeholder="$t('mycards.bind_tips')" allowClear />
          </div>
          <div class="bind-card-btn">
            <a-button type="primary" class="flex" :loading="isBinding" @click="handeBindCard">
              <a-icon type="plus-circle" theme="filled" v-show="!isBinding"/>
              <span>{{ $t('mycards.bind_btn') }}</span>
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: 'BindCard',
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    isBinding:{
      type: Boolean,
      default: false
    },
    xs: Number,
    sm: Number,
    md: Number,
    lg: Number,
    xl: Number
  },
  data() {
    return {
      iccid:'',
    }
  },
  mounted() {
    
  },
  methods: {
    handeBindCard(){
      if(!this.iccid) return
      this.$emit('bind',this.iccid)
    }
  },

}
</script>
<style lang="scss" scoped>
@mixin mobile-layout{
  .bind-card-img {
      justify-content: center;

      img {
        max-width: 100%;
      }
  }

  /deep/ .bind-card {
    text-align: center;

    &-title {
      font-size: 18px;
    }

    &-desc {
      font-size: 16px;
      margin-top: 6px;
      margin-bottom: 20px;
    }

    &-input {
      max-width: 100%;

      .ant-input {
        height: 50px;
        font-size: 16px;
      }

      .ant-input-suffix {
        right: 20px;

        .ant-input-clear-icon {
          font-size: 20px;
        }
      }
    }
    &-btn {
      max-width: 100%;
      margin: 20px 0;

      .ant-btn-primary {
        width: 100%;
        height: 50px;
        font-size: 18px;
      }
    }
  }
}
.bind-card {
  width: 100%;
  .bind-card-img {
    justify-content: flex-end;

    img {
      max-width: 420px;
    }
  }

  /deep/ .bind-card {
    &-title {
      color: #222222;
      font-size: 28px;
      font-weight: 600;
    }

    &-desc {
      color: #5D5D5D;
      font-size: 18px;
      margin-top: 16px;
      margin-bottom: 40px;
    }

    &-input {
      max-width: 400px;

      .ant-input {
        height: 56px;
        font-size: 18px;
        padding-left: 24px;
        padding-right: 44px;
        border-radius: 28px;
        border-color: $color;

        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .ant-input-suffix {
        right: 20px;

        .ant-input-clear-icon {
          font-size: 20px;
        }
      }
    }

    &-btn {
      max-width: 400px;
      margin: 20px 0;

      .ant-btn-primary {
        width: 100%;
        height: 56px;
        font-size: 20px;
        border-radius: 28px;
        background-color: $color;
      }
    }
  }
  .mobile-layout{
    @include mobile-layout; 
  }
  @include respond-to("phone") {
    @include mobile-layout; 
  }
}
</style>