<template>
  <div class="sim-card">
    <div class="sim-card-content">
      <div class="sim-card-header">
        <div class="iccid">ICCID: <span>{{ simNumber }}</span></div>
        <a-space>
          <a-button
            type="handle"
            class="unbind-btn"
            shape="round"
            ghost
            @click="handleUnbindCard"
          >
            {{$t('mycards.unbind')}}
          </a-button>
          <a-button
            type="leave"
            class="topup-btn"
            shape="round"
            @click="handleTopup"
          >
            {{$t('mycards.topup')}}
          </a-button>
        </a-space>
      </div>
      <div class="sim-card-plans">
        <slot name="subtitle"></slot>
        <div class="sim-card-plans-item" v-for="(item, index) in planList" :key="index">
          <div class="flex-top-start">
            <div class="sim-card-plans-item-img">
              <img :src="item.icon" alt="" srcset="" />
            </div>
            <div class="sim-card-plans-item-info">
              <div class="sim-card-plans-item-info-name">{{ item.full_name }}</div>
              <div class="sim-card-plans-item-info-time">
                {{ item.status == 2 ? item.active_at : item.end_at }}
              </div>
              <span
                class="sim-card-plans-item-info-status"
                :class="{ using: item.status == 2, expired:isExpired}"
                v-if="item.status_text"
              >
                {{ item.status_text }}
              </span>
            </div>
          </div>
          <div class="sim-card-plans-item-status-img" v-if="isExpired">
            <img v-if="item.status == 2" :src="require(`@/assets/used-${$i18n.locale}.png`)" alt="">
            <img v-else :src="require(`@/assets/expired-${$i18n.locale}.png`)" alt="">
          </div>
          <a-divider v-if="item.status == 2||item.status == 1 && index"/>
          <div class="sim-card-plans-item-handle flex-center-between">
            <div class="sim-card-plans-item-handle-progress" v-if="item.status == 2">
              <div class="sim-card-plans-item-handle-progress-used">{{$t('mycards.data_use')}} {{item.ratio}} %</div>
              <div class="sim-card-plans-item-handle-progress-text" v-html="$t('mycards.use_tips')"></div>
              <a-progress :percent="item.ratio * 1" strokeColor="#2564f6"/>
              <div class="sim-card-plans-item-handle-progress-text">{{isExpired?item.expire_end:item.expire_at}}</div>
            </div>
            <a-button
              v-if="item.status == 2 && index == 0"
              type="handle"
              class="stop-btn"
              shape="round"
              @click="handeStopPlan(item)"
            >
            {{$t('mycards.handle_stop')}}
            </a-button>
            <a-button
              v-else-if="item.status == 1 && index != 0"
              type="handle"
              class="use-btn"
              shape="round"
              @click="handeUsePlan(item,planList[0])"
            >
            {{$t('mycards.handle_use')}}
            </a-button>
            <!-- <a-button
              v-if="isExpired"
              type="handle"
              class="unbind-btn"
              shape="round"
              @click="handeAgain(item)"
            >
              {{$t('mycards.handle_again')}}
            </a-button> -->
          </div>
        </div>
        <div class="sim-card-plans-more flex" v-if="plans.length > planShowNum" @click="handleExpandPlans">
          {{isExpanded ? $t('mycards.fold') : `${$t('mycards.unfold')}(${plans.length - planShowNum})`}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Progress } from 'ant-design-vue';
export default {
  name: "SimCard",
  components: {
    [Progress.name]: Progress,
  },
  props: {
    iccid: {
      type: String,
      default: '',
    },
    plans: {
      type: Array,
      default: [],
    },
    isExpired:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      planShowNum: 2,
      isExpanded:false
    };
  },
  computed: {
    simNumber() {
      return this.iccid
        .trim()
        .replace(/\s/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    },
    planList(){
      if(this.isExpanded){
        return this.plans
      }else{
        return this.plans.slice(0,this.planShowNum)
      }
    }
  },
  methods: {
    async handeAgain(){
      
    },
    handeUsePlan(item,use){
      const { full_name:occupied } = use
      const {out_trade_orderno,full_name:intend} = item
      this.$emit('use',out_trade_orderno,intend,occupied)
    },
    handeStopPlan(item) {
      const {out_trade_orderno,full_name} = item
      this.$emit('stop',out_trade_orderno,full_name)
    },
    handleUnbindCard() {
      this.$emit('unbind',this.iccid)
    },
    handleExpandPlans(){
      this.isExpanded = !this.isExpanded
    },
    handleTopup(){
      this.$router.push({name:"Topup",query:{iccid:this.iccid}})
    }
  },
};
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "FjallaOne";
  src: url("https://img.triproaming.cn/fontfamily/FjallaOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

$handle-color: #b5282c;

.sim-card {
  padding: 20px;
  @include respond-to("phone"){
    padding: 10px;
  }

  /deep/ .ant-divider{
    margin: 10px 0 8px 0;
  }
  &-content {
    border-radius: 14px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }

  /deep/ &-header {
    padding: 20px 20px 16px 20px;
    border-bottom: 1px solid #8592a6;

    .iccid {
      color: #222222;
      font-size: 18px;
      font-family: "FjallaOne", sans-serif;
      margin-bottom: 4px;
      span{
        user-select: all;
      }
    }
  }

  &-plans {
    padding: 20px;
    &-more {
      cursor: pointer;
      margin-top: 10px;
      color: #222222;
    }
    &-item {
      position: relative;
      padding: 16px;
      margin-top: 14px;
      border-radius: 12px;
      border: 1px solid #8592a6;
      &-status-img{
        width: 80px;
        position: absolute;
        top: 6px;
        right: 10px;
      }
      &-img {
        margin-top: 4px;
        width: 40px;

        img {
          border-radius: 6px;
        }
      }

      &-info {
        margin-left: 10px;
        &-name {
          color: #222222;
          font-size: 16px;
          font-weight: 600;
        }

        &-time {
          font-size: 14px;
          margin-bottom: 4px;
        }

        &-status {
          padding: 2px 8px;
          color: $color;
          font-size: 12px;
          border-radius: 6px;
          border: 1px solid $color;
        }
        .using {
          color: $handle-color;
          border: 1px solid #b5282c;
        }
        .expired{
          color:#A3A3A3;
          border: 1px solid #A3A3A3;
        }
      }
      &-handle{
        &-progress{
          flex: 1;
          /deep/ .ant-progress{
            .ant-progress-text{
              display: none;
            }
          }
          &-used{
            color: $color;
            font-weight: 600;
          }
          &-text{
            color: #999999;
            font-size: 12px;
          }
        }

      }
    }
  }
  .ant-btn-handle {
    height: 30px;
    padding: 0 8px;
    font-size: 14px;
  }

  .ant-btn-leave {
    width: 130px;
    height: 30px;
    font-size: 14px;
  }

  .unbind-btn {
    color: $handle-color;
    border-color: $handle-color;
  }

  .topup-btn {
    color: #fff;
    border-color: $color;
    background-color: $color;
  }
  .stop-btn{
    color: #fff;
    border-color: $handle-color;
    background-color: $handle-color;
  }
  .use-btn{
    color: #fff;
    border-color: $color;
    background-color: $color;
  }
}
</style>
