<template>
  <div class="my-cards">
    <a-spin :spinning="isLoading" size="large">
    <div class="container">
      <div class="header-wrap flex-center-between">
        <div class="flex-center-start">
          <img src="../assets/cards_icon_1.png" alt="" srcset="">
          <span>{{ $t('mycards.tag_1') }}</span>
        </div>
        <div class="flex-center-start">
          <img src="../assets/cards_icon_2.png" alt="" srcset="">
          <span>{{ $t('mycards.tag_2') }}</span>
        </div>
        <div class="flex-center-start">
          <img src="../assets/cards_icon_3.png" alt="" srcset="">
          <span>{{ $t('mycards.tag_3') }}</span>
        </div>
      </div>
      <a-tabs v-if="hasCards">
        <a-tab-pane key="1" :tab="$t('mycards.tab_1')">
          <a-row type="flex">
            <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" v-for="(item,index) in simRecords.list" :key="index">
              <sim-card 
                :iccid="item.sim_number" 
                :plans="item.plan" 
                @unbind="handleUnbindCard"
                @stop="handeStopPlan"
                @use="handeUsePlan"
              >
                <template v-slot:subtitle>
                  <div>{{item.plan.length ? $t('mycards.last_time'):$t('mycards.empty')}}</div>
                </template>
              </sim-card>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" :disabled="!simRecords.overlist_nums" :tab="`${$t('mycards.tab_2')} (${simRecords.overlist_nums})`">
          <a-row type="flex">
            <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" v-for="(item,index) in simRecords.overlist" :key="index">
              <sim-card isExpired :iccid="item.sim_number" :plans="item.plan">
                <template v-slot:subtitle>
                  <div>{{item.plan.length ? $t('mycards.expired'):$t('mycards.empty')}}</div>
                </template>
              </sim-card>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-space slot="tabBarExtraContent" class="bind-card-btn" @click="visible = true">
          <img class="bind-card-icon" src="../assets/icon_addcard.png" alt="" srcset="">
          <span class="bind-card-text">
            {{$t('mycards.add_title')}}
          </span>
        </a-space>
      </a-tabs>
      <bind-card v-else :xs="24" :sm="24" :md="24" :lg="12" :xl="12" @bind="handleBindCard"/>
      <a-modal :title="$t('mycards.add_title')" :visible="visible" :footer="null" :width="400" @cancel="handleBindModalColse">
        <bind-card isModal :isBinding="isBinding" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" @bind="handleBindCard"/>
      </a-modal>
    </div>
  </a-spin>
  </div>
</template>

<script>
import BindCard from '../components/BindCard'
import SimCard from '../components/SimCard'
import { Tabs,Modal } from 'ant-design-vue';
export default {
  name:'MyCards',
  components: {
    BindCard,
    SimCard,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
  },
  data() {
    return {
      isLoading:false,
      isBinding:false,
      visible: false,
      simRecords:{
        list:[],
        overlist:[],
        overlist_nums:0
      },
    }
  },
  computed:{
    hasCards() {
      return this.simRecords.list.length > 0;
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      try {
        this.isLoading = true
        let {data:{list,overlist,overlist_nums}} = await this.$http.get(this.APIURL.MYCARDS.CARDS,{},false)
        list.sort((a, b) => {
          // 判断a和b中是否有status等于2的套餐
          const hasStatus2A = a.plan.some(item => item.status === 2);
          const hasStatus2B = b.plan.some(item => item.status === 2);

          // 如果a有status等于2的套餐而b没有，则a排在前面
          if (hasStatus2A && !hasStatus2B) {
            return -1;
          }
          // 如果b有status等于2的套餐而a没有，则b排在前面
          if (hasStatus2B && !hasStatus2A) {
            return 1;
          }
          // 如果a和b都有status等于2的套餐，或者都没有，则按照plan数组的长度降序排列
          return b.plan.length - a.plan.length;
        });
        this.simRecords = {list,overlist,overlist_nums}
        this.isLoading = false
      } catch (error) {
        
      }
    },
    handeUsePlan(out_trade_orderno,intend,occupied){
      Modal.confirm({
        width:440,
        title: this.$t('mycards.use'),
        icon: h => (<a-icon type="exclamation-circle" />),
        content: h => (
          <div>
            <span>{this.$t('mycards.use_tips_1')}</span>
            <span style="color:#2564f6;margin:0 4px;">{intend}</span>
            <span>{this.$t('mycards.use_tips_2')}</span>
            <span style="color:#2564f6;margin:0 4px;">{occupied}</span>
            <span>{this.$t('mycards.use_tips_3')}</span>
            <span>{this.$t('mycards.use_tips_4')}</span>
            <span style="color:#2564f6;margin:0 4px;">{intend}</span>
            <span>{this.$t('mycards.use_tips_5')}</span>
          </div>
        ),
        okText: this.$t('mycards.confirm'),
        okType: 'danger',
        cancelText: this.$t('mycards.cancel'),
        autoFocusButton:null,
        onOk:async()=> {
          try {
            await this.$http.post(this.APIURL.MYCARDS.USE,{out_trade_orderno},false)
            this.init()
          } catch (error) {
            return Promise.reject()
          }
        },
        onCancel() {},
      })
    },
    handeStopPlan(out_trade_orderno,full_name){
      Modal.confirm({
        width:440,
        title: this.$t('mycards.stop'),
        icon: h => (<a-icon type="exclamation-circle" />),
        content: h => (
          <div>
            <span>{this.$t('mycards.stop_tips_1')}</span>
            <span style="color:#B5282C;margin:0 4px;">{full_name}</span>
            <span>{this.$t('mycards.stop_tips_2')}</span>
          </div>
        ),
        okText: this.$t('mycards.confirm'),
        okType: 'danger',
        cancelText: this.$t('mycards.cancel'),
        autoFocusButton:null,
        onOk:async()=> {
          try {
            await this.$http.post(this.APIURL.MYCARDS.STOP,{out_trade_orderno},false)
            this.init()
          } catch (error) {
            return Promise.reject()
          }
        },
        onCancel() {},
      })
    },
    handleUnbindCard(iccid){
      Modal.confirm({
        width:440,
        title: this.$t('mycards.unbind'),
        icon: h => (<a-icon type="question" />),
        content: h => (
          <div>
            <span style="color:#B5282C;margin-right:4px;">
              {this.$t('mycards.unbind_text')}
            </span>
            <span>{this.$t('mycards.unbind_tips')}</span>
          </div>
        ),
        okText: this.$t('mycards.confirm'),
        okType: 'danger',
        cancelText: this.$t('mycards.cancel'),
        autoFocusButton:null,
        onOk:async()=> {
          try {
            await this.$http.post(this.APIURL.MYCARDS.UNBIND,{simcard:iccid},false)
            this.init()
          } catch (error) {
            return Promise.reject()
          }
        },
        onCancel() {},
      })
    },
    async handleBindCard(iccid){
      try {
        this.isBinding = true
        await this.$http.post(this.APIURL.MYCARDS.BIND,{simcard:iccid.replace(/ /g, "")},false)
        this.isBinding = false
        this.init()
        this.visible = false
      } catch (error) {
        this.isBinding = false
      }
    },
    handleBindModalColse() {
      this.visible = false
    }
  },
}
</script>

<style lang="scss" scoped>
.my-cards {
  padding: 80px 0;
  min-height: 70vh;
  .header-wrap {
    margin-bottom: 80px;
    justify-content: space-evenly;

    span {
      color: #222222;
      font-size: 18px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  .bind-card-btn{
    cursor: pointer;
    .bind-card-icon{
      max-width: 22px;
    }
    .bind-card-text{
      user-select: none;
      @include respond-to("phone"){
        display: none;
      }
    }
  }
  @include respond-to("phone") {
    padding: 20px 0;

    .header-wrap {
      margin-bottom: 40px;
      justify-content: space-evenly;
      img{
        max-width: 24px;
      }
      span {
        font-size: 12px;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}
</style>